import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Text from '../../Core/Text'
import DecoSmallIllus from '../../Illus/DecoSmallIllus'
import * as styles from './blogItem.module.scss'
import { useIntl } from '../../../../plugins/gatsby-plugin-intl-kley'
import replaceHife from '../../../utils/replaceHife'

export interface BlogItemProps {
  id: string
  _id: string
  title: string
  shortTitle: string
  category: string
  content: string
  name: string
  variant?: 'lg' | 'sm'
  tag?: keyof JSX.IntrinsicElements
  thumbnail: any
  color?: string
  _url?: string
  _urls?: {
    fr?: string
    en_us?: string
  }
}

export default function BlogItem(props: BlogItemProps) {
  const {
    title,
    shortTitle,
    category,
    content,
    name,
    variant = 'sm',
    tag = 'div',
    thumbnail,
    color = 'd85935',
    _url = '',
  } = props

  const { locale, defaultLocale } = useIntl()

  let useUrl = _url.replaceAll("'", "-")
  if (useUrl.endsWith('-?') || useUrl.includes('-?')) {
    useUrl = useUrl.replace('-?', '')
  }

  if (useUrl.endsWith('-!') || useUrl.includes('-!')) {
    useUrl = useUrl.replace('-!', '')
  }

  if (useUrl.endsWith('/$') ||useUrl.endsWith('$') || useUrl.includes('$')) {
    useUrl = useUrl.replace('$', '')
  }

  

  

  if (locale !== defaultLocale) {
    useUrl = `/${locale}` + useUrl
  }
  if(!useUrl.endsWith('/')) {
    useUrl = `${useUrl}/`
  }

  return (
    <Link
      to={useUrl}
      className={`${styles.blogItem} ${styles[`blogItem__${variant}`]}`}
    >
      <figure>
        {thumbnail &&
        (thumbnail.gatsbyImageData ||
          (thumbnail.childImageSharp &&
            thumbnail.childImageSharp.gatsbyImageData)) ? (
          <GatsbyImage
            image={
              thumbnail.childImageSharp
                ? thumbnail.childImageSharp.gatsbyImageData
                : thumbnail.gatsbyImageData
            }
            alt={shortTitle}
          />
        ) : thumbnail ? (
          <img src={thumbnail} alt={shortTitle} />
        ) : (
          <img src="/images/blogItem.png" alt={shortTitle} />
        )}
        <DecoSmallIllus className="deco" />
      </figure>
      <div className={styles.blogItemContent}>
        <Text
          tag={tag}
          as="h4"
          className="blog-title font-extrabold"
          dangerouslySetInnerHTML={{ __html: replaceHife(shortTitle) }}
        ></Text>
        <Text
          tag="div"
          as="captionH1"
          className="font-bold uppercase category"
          style={{ color: `#${color}` }}
          dangerouslySetInnerHTML={{ __html: replaceHife(category) }}
        ></Text>
        <Text
          tag="div"
          as="subtitleH3"
          className={styles.blogContent}
          dangerouslySetInnerHTML={{ __html: replaceHife(content) }}
        ></Text>
      </div>
    </Link>
  )
}
